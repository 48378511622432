import React from 'react';
import ThemeLayout from '../../layouts/layout';
import SignIn from '../../components/organisms/Auth/SignIn';

const SignInPage = () => (
  <ThemeLayout>
    <SignIn isModal={false} />
  </ThemeLayout>
);

SignInPage.propTypes = {};
SignInPage.defaultProps = {};

export default SignInPage;
